.custom-header {
  display: flex;
  flex-direction: column;
  padding-top: 13px;
  padding-left: 13px;
  font-size: 22px;
  text-decoration: none;
  color: #000;
  label {
    height: 40px;
    width: 200px;

    @media (max-width: 480px) {
    }
  }
  span {
    margin: 3px 0%;
    width: 100%;
    height: 1px;
    background: #eee;
    hr {
      border: none;
      position: absolute;
      top: 42px;
      left: 40px;
      width: 140px;
      background: #60d192;
      height: 6px;
    }
    @media (max-width: 480px) {
      background: transparent;
      width: 100%;
      hr {
        left: 35%;
      }
    }
  }
  @media (max-width: 480px) {
    align-items: center;
  }
}
