.custom-card-shipment {
  width: 80%;

  div {
    display: grid;

    transition: all 0.2s;
    padding: 5px 2px;
    align-items: flex-start;
  }
  @media (max-width: 600px) {
    width: 100%;
  }
}
.custom-card-shipment-container {
  display: flex;
  width: 50%;
  align-items: center;
  justify-content: space-between;
  border-top: 1px solid gray;
  border-bottom: 1px solid gray;
  @media (max-width: 600px) {
    width: 100%;
  }
}
.custom-card-shipment-iframe {
  width: 50%;
  height: 500px;
  @media (max-width: 600px) {
    width: 100%;
  }
}
