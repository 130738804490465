.splash-background {
  width: 100%;
  height: 100vh;
  background: rgb(251, 253, 255);
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  img {
    width: 300px;
    margin-bottom: 2%;
  }
}

.splash-text-h3 {
  color: black;
  font-size: 16px;
}
