.table-form-skydropx {
  .term-grid {
    width: 100%;
    margin: auto;
    display: grid;
    grid-template-columns: 1fr 2fr 1.5fr 1fr 1fr;
    grid-gap: 1em;
    color: #222;
    padding: 1em 0px;
    background: none;
    border: none;

    border-bottom: 1px solid gray;
    cursor: pointer;
  }
  .active-background {
    background: palegreen;
  }
  .term-grid label {
    font-weight: 800;
  }
  .term-grid label:first-child {
    text-align: right;
  }

  .grid-header {
    text-decoration: underline;
    border-bottom: unset;
    background: rgb(152, 251, 152);
  }
  .button-table-sky {
    width: 200px;
    height: 45px;
    border-radius: 6%;
    margin: 5% 0;
    button {
      width: 200px;
      height: 45px;
      border-radius: 6%;
      background: #4e34e1;
      border: none;
      color: #fff;
      font-size: 16px;
      font-weight: 600;
    }
    :hover {
      border: 1px solid black;
      cursor: pointer;
    }
    :focus {
      background: #4e34e165;
      border-radius: 6%;
    }
    @media (max-width: 600px) {
      display: none;
    }
  }
  .button-table-sky-new {
    margin: 10px 0px;
  }
  .button-table-mobile {
    display: none;
    width: 200px;
    height: 45px;
    border-radius: 6%;
    margin: 5% 0;
    button {
      width: 200px;
      height: 45px;
      border-radius: 6%;
      background: #4e34e1;
      border: none;
      color: #fff;
      font-size: 16px;
      font-weight: 600;
    }
    :hover {
      border: 1px solid black;
      cursor: pointer;
    }
    :focus {
      background: #4e34e165;
      border-radius: 6%;
    }
    @media (max-width: 600px) {
      display: flex;
      justify-content: center;
      align-items: center;
      width: 100%;
      margin: 10px;
    }
  }
  @media (max-width: 600px) {
    .term-grid {
      grid-template-columns: 1fr;
      grid-gap: 1em;
      padding: 1em 0;
      width: 300px;
    }

    .term-grid label:first-child {
      text-align: left;
      margin: 0;
    }

    .alternate {
      margin-top: -10px;
      font-style: italic;
      order: 1;
    }

    .definition {
      order: 2;
    }

    .grid-header {
      display: none;
    }
  }

  h2 {
    text-align: center;
  }

  .wrapper {
    padding: 0 1em;
  }
}
