.custom-input {
  display: flex;
  text-align: start;
  flex-direction: column;
  padding: 0% 2%;
  width: 60%;
  label {
    margin: 2% 0%;
    color: #000;
    font-size: 22px;
    font-weight: 500;
  }
  input {
    width: 100%;
    height: 45px;
  }
  p {
    color: rgb(253, 2, 2);
  }
}
