.custom-form {
  margin: 2% auto;
  width: 80%;
  box-shadow: 0 5px 8px 0 rgba(0, 0, 0, 0.2), 0 7px 20px 0 rgba(0, 0, 0, 0.2);
  position: relative;
  border-radius: 10px;
  height: 80vh;
  display: flex;
  flex-direction: column;
  @media (max-width: 600px) {
    margin: 0;
    width: 100%;
    box-shadow: none;
  }
}
