.custom-form-globe {
  width: 100%;
  height: 50px;
  display: flex;
  border-radius: 10px 10px 0px 0px;
  align-items: center;
  justify-content: space-between;
  background-size: cover;
  background: linear-gradient(60deg, #60d192, #60d19393, #60d192);
  background-size: 400% 400%;
  animation: gradient 15s ease infinite;
  img {
    width: 8%;
  }
}
.custom-form-inputs {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  height: 100%;
  border-radius: 0 0px 10px 10px;
  position: relative;
  background: white;
}
.button-form-sky {
  width: 200px;
  height: 45px;
  border-radius: 6%;
  margin: 5% 0;
  button {
    width: 200px;
    height: 45px;
    border-radius: 6%;
    background: #4e34e1;
    border: none;
    color: #fff;
    font-size: 16px;
    font-weight: 600;
  }
  :hover {
    border: 1px solid black;
    cursor: pointer;
  }
  :focus {
    background: #4e34e165;
    border-radius: 6%;
  }
}
@-webkit-keyframes gradient {
  0% {
    background-position: 0% 50%;
  }
  50% {
    background-position: 100% 50%;
  }
  100% {
    background-position: 0% 50%;
  }
}
@-moz-keyframes gradient {
  0% {
    background-position: 0% 50%;
  }
  50% {
    background-position: 100% 50%;
  }
  100% {
    background-position: 0% 50%;
  }
}
@keyframes gradient {
  0% {
    background-position: 0% 50%;
  }
  50% {
    background-position: 100% 50%;
  }
  100% {
    background-position: 0% 50%;
  }
}
