.custom-badge {
  position: absolute;
  padding: 5px 0;
  display: flex;
  width: 60px;
  height: 10px;

  @media (max-width: 800px) {
    margin: 0;
  }
}
.custom-badge-container {
  position: absolute;
  bottom: 1px;
  left: 5px;
  width: 100px;
  height: 25px;
  border-radius: 50%;
  border: 2px solid green;
  border-style: dashed solid;
  p {
    position: absolute;
    bottom: 5px;
    left: 100%;
    font-weight: 600;
  }
  @media (max-width: 600px) {
    position: absolute;
    top: -50px;
    margin: 0;
    width: 280px;
    height: 120px;
    p {
      position: absolute;
      top: -20px;
      left: 85%;
    }
  }
}
