.panel-container {
  width: 100%;
  position: relative;
  height: 100%;
  display: grid;
  grid-template-columns: 1fr 2fr;
  align-items: center;
  justify-content: center;
  @media (max-width: 800px) {
    height: 100%;
    grid-template-columns: 1fr;
  }
}
.container-right-panel {
  display: flex;

  align-items: center;
  flex-direction: column;

  @media (max-width: 800px) {
  }
}
.container-left-panel {
  display: flex;

  align-items: center;
  flex-direction: column;

  @media (max-width: 800px) {
  }
}
.container-button-panel {
  margin: 10px 0px;
}
