.custom-personal-info {
  display: flex;
  flex-direction: column;
  box-shadow: 0px 6px 10px rgba(0, 0, 0, 0.25);
  height: 245px;
  width: 50%;
  font-weight: 600;
  padding: 5%;
  @media (max-width: 800px) {
    margin: 0;
  }
}
.title-personal-info {
  font-weight: 600;
  font-size: 1.5rem;
  padding: 0;
  margin: 25px 0px 0px 0px;
}
