.custom-button {
  width: 200px;
  height: 45px;
  border-radius: 6%;

  button {
    width: 200px;
    height: 45px;
    border-radius: 6%;
    background: #4e34e1;
    border: none;
    color: #fff;
    font-size: 16px;
    font-weight: 600;
  }
  :hover {
    border: 1px solid black;
    cursor: pointer;
  }
  :focus {
    background: #4e34e165;
    border-radius: 6%;
  }
}
